import Vue from 'vue';
import jQuery from 'jquery'
window.jQuery = jQuery
window.$ = jQuery


import VuejsDialog from 'vuejs-dialog';

Vue.use(VuejsDialog, {
    html: false, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
    loader: false, // set to true if you want the dailog to show a loader after click on "proceed"
    reverse: false, // switch the button positions (left to right, and vise versa)
    okText: 'Weiter',
    cancelText: 'Abbrechen',
    animation: 'zoom', // Available: "zoom", "bounce", "fade"
    type: 'basic', // coming soon: 'soft', 'hard'
    verification: 'continue', // for hard confirm, user will be prompted to type this to enable the proceed button
    verificationHelp: 'Type "[+:verification]" below to confirm', // Verification help text. [+:verification] will be matched with 'options.verification' (i.e 'Type "continue" below to confirm')
    clicksCount: 3, // for soft confirm, user will be asked to click on "proceed" btn 3 times before actually proceeding
    backdropClose: false, // set to true to close the dialog when clicking outside of the dialog window, i.e. click landing on the mask
    customClass: '' // Custom class to be injected into the parent node for the current dialog instance
});

import './assets/scss/main.scss';

import router from "./router";
import store from "./store";
import Root from "./view/pages/Root";

require('./components');
require('./filter');
require('./functions');
require('./includes');
require('./plugins/http');
require('./plugins/api');

new Vue({
    el: '#app',
    render: h => h(Root),
    router: router,
    store: store
});
