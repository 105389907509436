import Vue from 'vue';

Vue.prototype.$arrayIndexObject = function (array, closure) {
    const item = array.find(closure);
    return array.indexOf(item);
};

Vue.prototype.$updateList = function (item, list) {
    var index = list.map(listItem => listItem.id).indexOf(item.id);

    if (index > -1) {
        list[index] = item;
        this.$forceUpdate();
    } else {
        list.unshift(item);
    }
};

Vue.prototype.$uniqueArray = function (arrArg) {
    let ids = [];
    let results = [];
    arrArg.forEach((item) => {
        if (ids.indexOf(item.id) === -1) {
            ids.unshift(item.id);
            results.unshift(item);
        }
    });

    return results;
};
