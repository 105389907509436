import Vue from 'vue';
import VueResource from 'vue-resource';
import VueNotification from "vue-notification";

Vue.use(VueResource);
Vue.use(VueNotification);

Vue.use({
        install(Vue) {
            Vue.prototype.$sendToApi = function (baseApiUrl, entity, callback) {
                let method = entity.id ? 'put' : 'post';
                let url = entity.id ? baseApiUrl + '/' + entity.id : baseApiUrl;

                this.$store.commit('incrementLoadingIndex');
                this.$http[method](url, entity).then(response => {
                    this.$store.commit('decrementLoadingIndex');
                    this.$notify({
                        type: 'success',
                        text: 'Erfolgreich gespeichert'
                    });
                    entity = response.data;
                    callback(entity, parseInt(response.status), {});
                }, response => {
                    this.$store.commit('decrementLoadingIndex');
                    this.$notify({
                        type: 'warning',
                        text: 'Fehler beim Speichern'
                    });
                    callback(entity, parseInt(response.status), response.data);
                });
            };

            Vue.prototype.$getFromApi = function (baseApiUrl, entity, callback) {
                let url = baseApiUrl + '/' + (typeof entity.id !== 'undefined' ? entity.id : entity);

                this.$store.commit('incrementLoadingIndex');
                this.$http['get'](url).then(response => {
                    this.$store.commit('decrementLoadingIndex');
                    entity = response.data;
                    callback(entity);
                }, response => {
                    this.$store.commit('decrementLoadingIndex');
                    this.$notify({
                        type: 'warning',
                        text: 'Daten konnten nicht geladen werden'
                    });
                    callback(entity);
                });
            };

            Vue.prototype.$deleteFromApi = function (baseApiUrl, entity, callback) {
                let url = baseApiUrl + '/' + (typeof entity.id !== 'undefined' ? entity.id : entity);

                this.$store.commit('incrementLoadingIndex');
                this.$http['delete'](url).then(response => {
                    this.$store.commit('decrementLoadingIndex');
                    entity = response.data;
                    callback(null);
                }, response => {
                    this.$store.commit('decrementLoadingIndex');
                    this.$notify({
                        type: 'warning',
                        text: 'Daten konnten nicht gelöscht werden.'
                    });
                    callback(entity);
                });
            };
        }
    }
)

