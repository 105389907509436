<template>
    <div class="row pMap">
        <div class="small-12 columns">
            <h1 class="pMap__headline">Lebensmittelindustriekarte</h1>
        </div>
        <div class="small-12 columns">
            <div class="panel --noPadding">
                <div class="row collapse align-stretch">
                    <form v-on:submit.prevent="search" class="small-12 medium-12 columns">
                        <div class="pMap__form">
                            <div class="row align-middle">
                                <div class="columns">
                                    <input-text
                                            type="text"
                                            v-model="companyQuery.name"
                                            placeholder="Name des Unternehmens"
                                    />
                                </div>
                                <div class="column shrink">
                                    <button type="submit" class="button">Suchen</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="small-12 medium-6 columns">
                        <div v-if="currentCompany" class="vMap__companyDetail">
                            <h2 style="margin-bottom: 1rem">{{currentCompany.name}}</h2>
                            <p v-if="currentCompany.address">{{currentCompany.address}}</p>
                            <p v-if="currentCompany.terms">{{currentCompany.terms}}</p>
                            <a v-if="currentCompany.url">{{currentCompany.url}}</a>
                            <br>
                            <br>
                            <a @click="currentCompany = null">Schließen</a>
                        </div>
                        <div v-if="!currentCompany">
                            <table>
                                <thead>
                                <tr>
                                    <td>Name</td>
                                    <td>Kategorie</td>
                                    <td class="show-for-large">Ort</td>
                                </tr>
                                </thead>
                                <tbody>

                                <tr v-if="companies.length === 0">
                                    <td colspan="3">
                                        <div class="callout warning">
                                            Keine Unternehmen gefunden.
                                        </div>
                                    </td>
                                </tr>

                                <tr v-for="company in companies">
                                    <td>{{company.name}}</td>
                                    <td>
                                        <span class="badge" v-if="company.terms">{{company.terms}}</span>
                                    </td>
                                    <td class="show-for-large">
                                        <template v-if="company.address">{{company.address}}</template>
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                            <div class="text-center pagination">
                                <a v-if="companyQuery.page > 1" @click="companyQuery.page--">Vorherige Seite</a>
                                <a v-if="companyQuery.page < companyQuery.maxPages" @click="companyQuery.page++">Nächste
                                    Seite</a>
                            </div>
                        </div>
                    </div>
                    <div class="small-12 medium-6 columns">
                        <div class="pMap__mapWrapper">
                            <div id="map_canvas" class="pMap__canvas" ref="mapCanvas" style="height: 100%;"></div>
                            <div id="map_companies"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                map: {},
                markers: [],
                markerCluster: {},
                companies: [],
                companyQuery: {
                    maxPages: 0,
                    page: 1,
                    itemsPerPage: 10
                },
                currentCompany: null
            }
        },
        mounted() {
            this.initialize();
            this.loadPages();
        },
        watch: {
            'companyQuery.page': function () {
                this.loadPages();
            },
            'currentCompany': function (newVal) {
                if (newVal === null) {
                    this.map.setCenter(new google.maps.LatLng(51.3103484, 10.339681400000018));
                    this.map.setZoom(6);
                }
            }
        },
        methods: {
            search() {
                this.companyQuery.page = 1;
                this.loadPages();
                this.getLocations();
            },
            loadPages() {
                this.$http.get('companies', {params: this.companyQuery}).then(response => {
                    this.companies = response.data['hydra:member'];
                    this.companyQuery.maxPages = Math.floor(response.data['hydra:totalItems'] / 10) + 1;
                })
            },
            setAllMap(map) {
                for (var i = 0; i < this.markers.length; i++) {
                    this.markers[i].setMap(map);
                }
            },
            clearMarkers() {
                this.setAllMap(null);
            },
            deleteMarkers() {
                this.clearMarkers();
                this.markers = [];
                this.map.setCenter(new google.maps.LatLng(51.3103484, 10.339681400000018));
                this.map.setZoom(6);
            },
            getLocations() {
                var bounds = new google.maps.LatLngBounds();
                var latlngbounds = new google.maps.LatLngBounds();
                var infoWindow = new google.maps.InfoWindow(), marker, i;

                var query = Object.assign({}, this.companyQuery);
                query.itemsPerPage = 3000;
                this.$http.get('companies', {params: query}).then(response => {
                    this.deleteMarkers(); // clear
                    this.markerCluster.clearMarkers();
                    var locations = response.data['hydra:member'];

                    for (var i = 0; i < locations.length; i++) {
                        var position = new google.maps.LatLng(locations[i].lat, locations[i].lng);
                        bounds.extend(position);

                        marker = new google.maps.Marker({
                            position: position,
                            map: this.map,
                            title: locations[i].post_title
                        });

                        if (locations[i].icon) {
                            marker.setIcon(locations[i].icon);
                        }

                        let _this = this;
                        google.maps.event.addListener(marker, 'click', (function (marker, i) {
                            return function () {
                                _this.map.setZoom(15);
                                _this.map.setCenter(marker.getPosition());

                                _this.showCompany(locations[i]['id']);
                            }
                        })(marker, i));


                        latlngbounds.extend(new google.maps.LatLng(locations[i].lat, locations[i].lng));
                        this.markers.push(marker);
                    }

                    this.markerCluster.addMarkers(this.markers);
                    this.map.fitBounds(latlngbounds);

                    console.log(locations.length);

                    if (parseInt(response.data['hydra:totalItems']) === 0) {
                        this.map.setCenter(new google.maps.LatLng(51.3103484, 10.339681400000018));
                        this.map.setZoom(6);
                    }
                });
            },
            initialize() {
                var mapOptions = {
                    mapTypeId: google.maps.MapTypeId.ROADMAP
                };

                this.map = new google.maps.Map(document.getElementById('map_canvas'), mapOptions);
                this.map.setTilt(45);

                this.map.setCenter(new google.maps.LatLng(51.3103484, 10.339681400000018));
                this.map.setZoom(6);

                this.markerCluster = new MarkerClusterer(this.map, [], {imagePath: window.baseUrl + 'assets/m'})

                jQuery('.marker-link').on('click', function () {
                    google.maps.event.trigger(this.markers[jQuery(this).data('marker')], 'click');
                });

                this.getLocations();
            },
            showCompany(companyID) {
                this.$http.get('companies/' + companyID).then(response => {
                    this.currentCompany = response.data;
                });
            }
        }
    }
</script>
