import jQuery from 'jquery';
import Vue from 'vue';
import Notifications from 'vue-notification';
import VuejsDialog from "vuejs-dialog";

Vue.use(Notifications);
Vue.use(VuejsDialog, {
    html: true,
    loader: false,
    okText: 'OK',
    cancelText: 'Abbrechen',
    animation: 'fade',
});

Vue.config.productionTip = false;

window.$ = jQuery;
window.jQuery = jQuery;
