<template>
    <div class="main" :class="{'--loading': showLoading}">
        <loading v-show="showLoading"/>
        <div class="row">
            <div class="columns small-12">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            showLoading: {
                type: Boolean
            }
        }
    }
</script>
