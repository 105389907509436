<template>
    <div>
        <div class="panel">
            <div class="row">
                <div class="large-12 small-12 columns">
                    <h1>Registrieren</h1>
                    <div class="callout success h-large-marginBottom">
                        <p class="--large">Registrieren Sie sich jetzt kostenlos und erhalten Zugriff auf unsere
                            Lebensmittelindustriekarte mit über
                            1800 Unternehmen der Lebensmittel-, Getränke- und Zulieferindustrie.</p>
                    </div>
                </div>
                <div class="large-12 small-12 columns">
                    <registration-form/>
                </div>
            </div>
        </div>

        <div class="text-center">
            <router-link to="/passwort-vergessen">Passwort vergessen</router-link>
            -
            <router-link to="/login">Zum Login</router-link>
        </div>
    </div>
</template>

<script>
    import RegistrationForm from "../../components/data/RegistrationForm";

    export default {
        components: {
            RegistrationForm
        },
        methods: {
            showLoading() {
                this.$parent.showLoading();
            },
            hideLoading() {
                this.$parent.hideLoading();
            }
        }
    }
</script>
