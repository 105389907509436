<template></template>

<script>
    export default {
        mounted() {
            if (this.$route.params.confirmationToken) {
                this.$http.post('actions/confirm', {confirmationToken: this.$route.params.confirmationToken}).then(
                    response => {
                        this.$notify({
                            type: 'success',
                            text: 'Benutzeraccount erfolgreich bestätigt. Sie können sich nun einloggen.'
                        });
                        this.$router.push('/login');
                    },
                    response => {
                        this.$notify({
                            type: 'alert',
                            text: 'Benutzeraccount konnte nicht bestätigt werden. Entweder wurde kein Account gefunden oder der Account wurde schon bestätigt.'
                        });
                        this.$router.push('/registrierung');
                    }
                )
            } else {
                this.$router.push('/register');
            }
        }
    }
</script>
