import Vue from 'vue';

/*Vue.filter('formatDate', function (value, format) {
    return moment(value).format(format);
});*/

Vue.filter('tSecurity', function (value) {
    return window.global.credibilities[value];
});

Vue.filter('tStatus', function (value) {
    var translations = {
        current_active: 'Aktuell aktiv',
        active: 'Aktiv',
        planed: 'Geplant',
        closed: 'Abgelaufen',
        inactive: 'Inaktiv',
    };

    return translations[value];
});

Vue.filter('tMenuItemType', function (value) {
    var translations = {
        page: 'Seite',
        destination: 'Link'
    };

    return translations[value];
});

Vue.filter('niceFileSize', function (value) {
    let KB = Math.round(value / 1024);
    if (KB > 1000) {
        return Math.round(KB / 1024 * 10) / 10 + ' MB';
    }
    return Math.round(KB / 10) * 10 + ' KB';
});
