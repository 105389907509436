export default {
    mapConstraints(data) {
        let errors = {};

        if(typeof data.violations !== 'undefined') {
            data.violations.forEach((item) => {
                if(typeof errors[item.pathProperty] === 'undefined') {
                    errors[item.propertyPath] = [];
                    errors[item.propertyPath].push(item.message);
                } else {
                    errors[item.propertyPath].push(item.message);
                }
            });
        }

        return errors;
    }
}
