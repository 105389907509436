<template>
    <div>
        <div class="panel">
            <div class="row">
                <div class="small-12 large-12 columns">
                    <h1>Passwort setzen</h1>
                </div>
                <div class="small-12 large-12 columns">
                    <form v-on:submit.prevent="passwordReset()">
                        <input-text v-model="plainPassword" label="Passwort" type="password"
                                    :errors="formErrors.plainPassword"/>
                        <input-text v-model="plainPasswordRepeat" label="Passwort (Wied.)"
                                    :errors="formErrors.plainPasswordRepeat"
                                    type="password"
                        />

                        <div class="text-center">
                            <button type="submit" class="button">Neues Passwort setzen</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="text-center">
            <router-link to="/login">Zurück zum Login</router-link>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                plainPassword: '',
                plainPasswordRepeat: '',
                formErrors: {}
            }
        },
        methods: {
            passwordReset() {
                this.formErrors = {};
                this.$parent.showLoading();

                if (this.plainPassword !== this.plainPasswordRepeat) {
                    this.formErrors = {
                        plainPassword: [
                            "Die Passwörter müssen identisch sein."
                        ]
                    };
                    this.$parent.hideLoading();

                    return;
                }

                this.$http.post("actions/passwordReset", {
                    plainPassword: this.plainPassword,
                    passwordResetToken: this.$route.params.passwordResetToken
                }).then(response => {
                    this.$notify({
                        text: 'Das Passwort wurde erfolgreich geändert. Sie können sich nun mit dem neuen Passwort einloggen',
                        type: 'success'
                    });
                    this.$parent.hideLoading();
                    this.$router.push('/login');
                }, response => {
                    this.formErrors = {
                        plainPassword: response.body.violations.filter((item) => {
                            return item.propertyPath === 'plainPassword'
                        }).map(item => item.message)
                    };

                    this.$parent.hideLoading();
                });
            }
        }
    }
</script>
