<template>
</template>

<script>
    import auth from "../../../plugins/auth";
    export default {
        mounted() {
            auth.clearAuth();
            this.$router.push("/login");
        }
    }
</script>
