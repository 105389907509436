import Vue from 'vue'
import InputText from "./view/components/form/InputText";
import ContentMain from "./view/components/ContentMain";
import ContentHeader from "./view/components/ContentHeader";
import Loading from "./view/components/Loading";
import Reveal from "./view/components/Reveal";

Vue.component('reveal', Reveal);
Vue.component('loading', Loading);
Vue.component('input-text', InputText);
Vue.component('content-main', ContentMain);
Vue.component('content-header', ContentHeader);
