<template>
    <div class="header">
        <div class="row align-middle">
            <div class="columns">
                <div class="header__headline">{{headline}}</div>
                <div class="header__subline" v-if="subline !== false">{{subline}}</div>
            </div>
            <div class="columns shrink">
                <div class="header__actions">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            headline: {
                type: String,
                default: function () {
                    return '';
                }
            },
            subline: {
                type: String|Boolean,
                default: function () {
                    return false;
                }
            },
        }
    }
</script>
