import Vue from 'vue';
import Vuex from 'vuex';
import VueResource from 'vue-resource';

Vue.use(Vuex);
Vue.use(VueResource);

export default new Vuex.Store({
    state: {
        loggedInUser: {},
        environment: {},
        loadingIndex: 0,
    },
    getters: {
        getEnvironment(state, getters) {
            return state.environment;
        },
        getLoggedInUser(state, getters) {
            return state.loggedInUser;
        },
        hasLoggedInUserCredibility: (state) => (credibility) => {
            return state.loggedInUser.credibilitySlugs.indexOf(credibility) > -1;
        },
        getLoadingIndex(state, getters) {
            return state.loadingIndex;
        }
    },
    mutations: {
        setEnvironment(state, env) {
            state.environment = env;
        },
        setLoggedInUser(state, user) {
            state.loggedInUser = user;
        },
        incrementLoadingIndex(state) {
            state.loadingIndex++;
        },
        decrementLoadingIndex(state) {
            state.loadingIndex--;
        }
    },
    actions: {
        getEnvironment(context) {
            return context.commit('setEnvironment', process.env.NODE_ENV);
        },
        reloadLoggedInUser(context, payload) {
            Vue.http.get('users/current_user').then(response => {
                context.commit('setLoggedInUser', response.data);
                return context.loggedInUser;
            }, response => {
                context.commit('setLoggedInUser', null);
                return null;
            });
        },
        getLoggedInUser(context, payload) {
            if (context.loggedInUser) {
                return context.loggedInUser;
            } else {
                Vue.http.get('users/current_user').then(response => {
                    context.commit('setLoggedInUser', response.data);
                    return context.loggedInUser;
                }, response => {
                    context.commit('setLoggedInUser', null);
                    return null;
                });
            }
        }
    }
});
