<template>
    <div>
        <div class="panel">
            <div class="row">
                <div class="small-12 large-12 columns">
                    <h1>Login</h1>
                </div>
                <div class="small-12 large-12 columns">
                    <form v-on:submit.prevent="login()">
                        <input-text v-model="email" label="E-Mail" :errors="formErrors.email"/>
                        <input-text v-model="password" type="password" label="Passwort"/>

                        <div class="text-center">
                            <button type="submit" class="button">Einloggen</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div class="text-center">
            <router-link to="/registrierung">Registrieren</router-link> - <router-link to="/passwort-vergessen">Passwort vergessen</router-link>
        </div>
    </div>
</template>

<script>
    import auth from '../../../plugins/auth';

    export default {
        data() {
            return {
                email: '',
                password: '',
                formErrors: {}
            }
        },
        mounted() {
            if(typeof this.$route.query['newPassword'] !== 'undefined') {
                this.$notify({
                    type: 'success',
                    text: 'Passwort erfolgreich geändert. Sie können sich nun mit den neuen Daten einloggen.'
                });
            }
        },
        methods: {
            login() {
                this.formErrors = {};
                this.$parent.showLoading();
                this.$http.post("token", {email: this.email, password: this.password}).then(response => {
                    auth.setAuth(response.data.token);
                    auth.setRefreshToken(response.data.refresh_token);
                    this.$parent.login();
                }, response => {
                    this.formErrors = {
                        email: [
                            "Kein Benutzer gefunden oder Passwort falsch"
                        ]
                    };

                    this.$parent.hideLoading();
                });
            }
        }
    }
</script>
